// Include Bootstrap
import * as bootstrap from "bootstrap";

// Include jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;

// NProgress
import NProgress from "nprogress";
window.NProgress = NProgress;
NProgress.configure({
	showSpinner: false,
});
NProgress.start();
NProgress.set(0.4);
var interval = setInterval(() => {
	NProgress.inc();
}, 1000);
window.addEventListener("load", () => {
	NProgress.done();
	clearInterval(interval);
});

$(document).ready(function () {
	// Remove default layout stuff
	$("#cover").remove();
	$("style#portal").remove();
	$("div#content div.layout:first").remove();
	// General scripts
	let mwidth = 767;
	if (window.innerWidth <= mwidth) {
		$("#header").addClass("sticky-top");
	}
	$(window).resize(function () {
		if (window.innerWidth <= mwidth) {
			$("#header").addClass("sticky-top");
		} else {
			$("#header").removeClass("sticky-top");
		}
	});

	// Add bootstrap form styles
	$("input[type=submit],input[type=button]").removeClass().addClass("btn btn-congress-blue");

	$(".triage-search-form button,.find-legal-help-form button,.find-legal-help-results .btn,.find-legal-help-header +.btn").addClass(
		"btn btn-congress-blue"
	);

	// Trim button space
	$("input[type=submit],input[type=button], div.back a").each(function () {
		var value = $(this).val();
		$(this).val($.trim(value));
	});

	//header toggle
	$("#header-banner .show-btn,#header-banner #header-banner-content .close-btn").click(function () {
		$("#header-banner #header-banner-content").toggle(300);
		$("#header-banner .show-btn").toggle(300);
	});

	// Gtranslate stuff
	$(".gtranslate").on("click", function (e) {
		e.preventDefault();
		doGTranslate($(this).data("lang-pair"));
	});
	function doGTranslate(lang_pair) {
		if (lang_pair.value) lang_pair = lang_pair.value;
		if (lang_pair == "") return;
		var lang = lang_pair.split("|")[1];
		var plang = location.hostname.split(".")[0];
		if (plang.length != 2 && plang.toLowerCase() != "zh-cn" && plang.toLowerCase() != "zh-tw") plang = "en";
		location.href =
			location.protocol +
			"//" +
			(lang == "en" ? "" : lang + ".") +
			location.hostname.replace("www.", "").replace(RegExp("^" + plang + "\\."), "") +
			location.pathname +
			location.search;
	}

	// Session stuff
	function getSessionId() {
		return getCookie("SESSIONID") ? getCookie("SESSIONID") : setCookie("SESSIONID", guid(), 365);
	}

	// Cookie stuff
	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		return cvalue;
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(";");
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == " ") {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function guid() {
		return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
	}

	function s4() {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
	}
});
